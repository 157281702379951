<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-model="show_modal"
    :click-to-close="false"
  >
    <!-- layer : 여백 입력 -->
    <section id="modalResultBlank" class="layer_dialog">
      <h2 class="mb20">여백 입력</h2>
      <input type="text" name="" v-model="blank" @input="onlyNumber($event)" />
      px

      <div class="btns">
        <ul>
          <li>
            <button
              type="button"
              @click="close"
              class="btn btn_large btn_secondary"
            >
              <span>닫기</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              @click="setResult"
              class="btn btn_large btn_primary"
            >
              <span>저장</span>
            </button>
          </li>
        </ul>
      </div>
    </section>
    <!-- //layer : 여백 입력 -->
  </vue-final-modal>
</template>

<script>
import { mapState } from 'vuex';
import common from '@/components/mixin/index.js';

export default {
  mixins: [common],
  inheritAttrs: false,
  data: () => ({
    show_modal: true,
    blank: '20',
  }),
  props: ['item', 'type', 'result_desktop_items', 'result_mobile_items'],
  created: async function () {
    this.init();
  },
  computed: {},
  mounted() {},
  methods: {
    init: function () {
      if (this.item !== null) {
        this.blank = this.item.blank;
      }
    },
    setResult: function () {
      if (this.blank === '') {
        alert('여백 값을 입력해주세요.');
        return false;
      }

      const params = {
        type: 'blank',
        text: '여백',
        blank: this.blank,
      };

      if (this.item !== null) {
        if (this.type === 'desktop') {
          this.result_desktop_items.splice(this.item.index, 1, params);
        } else {
          this.result_mobile_items.splice(this.item.index, 1, params);
        }
      } else {
        if (this.type === 'desktop') {
          this.result_desktop_items.push(params);
        } else {
          this.result_mobile_items.push(params);
        }
      }

      this.$emit('updateList', 'desktop', this.result_desktop_items);
      this.$emit('updateList', 'mobile', this.result_mobile_items);

      this.show_modal = false;
    },
    setColor: function (e) {
      const key = e.target.id;
      const hex = e.target.value;

      this.color = hex;
    },
    onlyNumber: function (event) {
      let value = event.target.value.replace(/[^0-9.]/g, '');
      if (value === '') {
        value = 0;
      }

      value = parseInt(value);

      if (value >= 9999) {
        value = 9999;
      }

      this.blank = value;
      event.target.value = value;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
